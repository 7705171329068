<template>
    <b-card class="card">
        <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
            <!-- Form -->
            <b-overlay :show="show" rounded="sm">
                <b-form class="p-2" @submit.prevent="handleSubmit(onSubmit)" @reset.prevent="resetForm">
                    <!-- <b-row class="mt-3"> -->
                    <b-row>
                        <b-col cols="12" sm="6" md="4">
                            <b-form-group label="Title" label-for="title">
                                <b-form-input id="title" v-model="form.title" placeholder="type a title" ref="titleTextBox" />
                            </b-form-group>
                        </b-col>
                        <b-col cols="2" sm="2" md="2">
                            <b-button @click="titleEmojiBtn" v-ripple.400="'rgba(40, 199, 111, 0.15)'" variant="outline-warning" class="btn-icon rounded-circle mt-1">
                                <feather-icon class="icon" icon="SmileIcon" />
                            </b-button>
                        </b-col>
                        <b-col cols="12" sm="6" md="4">
                            <b-form-group label="Notification Type" label-for="type">
                                <v-select :disabled="unEditable" v-model="form.notifable_type" :options="sourceOption" label="type" input-id="type" placeholder="Please first select the type" @input="fetchTypeList(form.notifable_type)" />
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <b-row class="mb-1">
                        <b-col cols="12" sm="6" md="10">
                            <VEmojiPicker class="emojiBox" v-if="titleEmojiAllowed" @select="insertTitleEmoji" />
                        </b-col>
                    </b-row>

                    <b-col v-if="form.notifable_type == 'music'" cols="12" sm="6" md="4">
                        <b-form-group label="Music" label-for="type">
                            <v-select :disabled="unEditable" v-model="form.notifable_id" :options="musicOption" label="name" input-id="type" placeholder="Choose the music" :loading="isLoading" @search="filterMusic" :reduce="(val) => val.id" />
                        </b-form-group>
                    </b-col>
                    <b-col v-if="form.notifable_type == 'artist'" cols="12" sm="6" md="4">
                        <b-form-group label="Artists" label-for="type">
                            <v-select :disabled="unEditable" v-model="form.notifable_id" :options="artistOption" value="id" label="name" input-id="type" placeholder="Choose the artist" :loading="isLoading" @search="filterArtist" :reduce="(val) => val.id" />
                        </b-form-group>
                    </b-col>
                    <b-col v-if="form.notifable_type == 'video'" cols="12" sm="6" md="4">
                        <b-form-group label="Videos" label-for="type">
                            <v-select :disabled="unEditable" v-model="form.notifable_id" :options="videoOption" label="name" input-id="type" placeholder="Choose the video" :loading="isLoading" :reduce="(val) => val.id" />
                        </b-form-group>
                    </b-col>
                    <b-col v-if="form.notifable_type == 'playlist'" cols="12" sm="6" md="4">
                        <b-form-group label="Playlists" label-for="type">
                            <v-select :disabled="unEditable" v-model="form.notifable_id" :options="playlistOption" label="name" input-id="type" placeholder="Choose the playlist" :loading="isLoading" :reduce="(val) => val.id" />
                        </b-form-group>
                    </b-col>
                    <b-col v-if="form.notifable_type == 'composer'" cols="12" sm="6" md="4">
                        <b-form-group label="Composer" label-for="type">
                            <v-select :disabled="unEditable" v-model="form.notifable_id" :options="composerOption" label="name" input-id="type" placeholder="Choose the composer" :loading="isLoading" :reduce="(val) => val.id" />
                        </b-form-group>
                    </b-col>
                    <b-col v-if="form.notifable_type == 'advertise'" cols="12" sm="6" md="4">
                        <b-form-group label="Advertise" label-for="type">
                            <b-form-input id="name" v-model="form.link" placeholder="Enter the Advertise Link" :loading="isLoading" />
                        </b-form-group>
                    </b-col>
                    <div class="row justify-content-center" v-if="form.notifable_type == 'live_chats'">
                            <b-form-group class="col-6" label="Live Chat" label-for="type">
                                <v-select :disabled="unEditable" v-model="form.notifable_id" :options="livechatOption" label="title" input-id="type" placeholder="Choose the Live" :loading="isLoading" @search="filterLiveChat" :reduce="(val) => val.id">
                                    <template #option="{ title, thumbnail }">
                                        <div class="row justify-content-between">
                                            <span class="col-md-6">{{ title }}</span>
                                            <b-img class="mb-1 col-md-2 mb-sm-0" rounded="circle" height="50" :src="thumbnail" />
                                        </div>
                                    </template>
                                </v-select>
                            </b-form-group>
                    </div>
                    <!-- </b-row> -->
                    <b-row class="justify-content-center" v-if="form.notifable_type == 'advertise'">
                        <b-col cols="12" sm="5" md="5">
                            <!-- upload button -->

                            <image-cropper v-model="fileForm" label="manager image" :url.sync="form.thumbnail" />
                            <b-card-text>Allowed JPG, GIF or PNG. Max size of 800kB </b-card-text>
                            <!--/ upload button -->
                        </b-col>
                    </b-row>
                    <b-row class="justify-content-center" v-if="form.notifable_type == 'live_chats'">
                        <b-col cols="12" sm="4" md="6">
                            <!-- upload button -->

                            <image-cropper v-model="fileForm" label="manager image" :url.sync="form.thumbnail" />
                            <b-card-text>Allowed JPG, GIF or PNG. Max size of 800kB </b-card-text>
                            <!--/ upload button -->
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col cols="10">
                            <b-form-group label="Users" label-for="user">
                                <v-select v-model="userIds" :loading="isLoading" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="users" label="user_name" :clearable="false" input-id="user" @search="filterUsers" multiple placeholder="You Can Select Multiple Users..." />
                            </b-form-group>
                        </b-col>

                        <b-col cols="2">
                            <b-form-checkbox v-model="form.send_to_all" :value="true" class="custom-control-danger mt-2"> Send to All Users </b-form-checkbox>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col cols="12" sm="6" md="12">
                            <!-- <b-form-group label="Text" label-for="text">
                                <b-form-textarea v-model="form.text" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :reduce="(val) => val.value" input-id="text" rows="4" placeholder="Type your Notification Text..." ref="inputRef" />
                            </b-form-group> -->
                            <textarea ref="textBox" v-model="form.text" class="textBoxArea"></textarea>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col cols="2">
                            <b-button @click="emojiBtn" v-ripple.400="'rgba(40, 199, 111, 0.15)'" variant="outline-warning" class="btn-icon rounded-circle">
                                <feather-icon class="icon" icon="SmileIcon" />
                            </b-button>
                        </b-col>
                        <b-col cols="7">
                            <VEmojiPicker class="emojiBox" v-if="emojiAllowed" @select="insertEmoji" />
                        </b-col>
                    </b-row>
                    <b-row class="row justify-content-center">
                        <!-- Form Actions -->
                        <b-col class="text-center" cols="12">
                            <div class="mt-2">
                                <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="danger" class="mr-2" type="submit"> Send </b-button>
                            </div>
                        </b-col>
                    </b-row>
                </b-form>
            </b-overlay>
        </validation-observer>
    </b-card>
</template>

<script>
import { BFormCheckbox, BFormSelect, BTab, BTabs, BCard, BAlert, BFormGroup, BForm, BRow, BCol, BFormInput, BMedia, BFormFile, BImg, BFormTextarea, BCardText, BButton, BMediaBody, BMediaAside, BFormInvalidFeedback, BOverlay } from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import formValidation from "@core/comp-functions/forms/form-validation";
import { required } from "@validations";
import { ref, onBeforeUnmount, onMounted, watchEffect } from "@vue/composition-api";
import router from "@/router";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import { makeid } from "@/utils/math";
import useNotificationList from "./useNotificationList";
import useGenre from "../genre/useGenre";
import useFile from "../file/useFile";
import ArtistRepository from "@/abstraction/repository/artistRepository";
import MusicRepository from "@/abstraction/repository/musicRepository";
import VideoRepository from "@/abstraction/repository/videoRepository";
import PlaylistRepository from "@/abstraction/repository/playListRepository";
import ComposerRepository from "@/abstraction/repository/composerRepository";
import UserRepository from "@/abstraction/repository/userRepository";
import LiveChatRepository from "@/abstraction/repository/liveChatRepository";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { VEmojiPicker } from "v-emoji-picker";
const userRepository = new UserRepository();
const RANDOM_TOKEN = makeid(50);

export default {
    components: {
        VEmojiPicker,
        BOverlay,
        BTab,
        BFormInput,
        BFormCheckbox,
        BFormInvalidFeedback,
        BFormGroup,
        BForm,
        BFormTextarea,
        BFormFile,
        BImg,
        BMedia,
        BTabs,
        BCard,
        BAlert,
        BRow,
        BCol,
        vSelect,
        BFormSelect,
        BButton,
        BCardText,
        BMediaAside,
        BMediaBody,
        // Form Validation
        ValidationProvider,
        ValidationObserver,
    },
    directives: {
        Ripple,
    },
    props: {
        modelValue: {
            type: String,
            default: "",
        },
    },
    setup(props, context) {
        let blankForm = {
            send_to_all: false,
            text: "",
            title: "",
            file_batch_id: RANDOM_TOKEN,
        };
        const textArea = ref();
        const userIds = ref();
        const artistRepository = new ArtistRepository();
        const musicRepository = new MusicRepository();
        const videoRepository = new VideoRepository();
        const playlistRepository = new PlaylistRepository();
        const composerRepository = new ComposerRepository();
        const livechatRepository = new LiveChatRepository();
        const slideShowId = ref(null);
        const isLoading = ref(false);
        const artistOption = ref(undefined);
        const musicOption = ref(undefined);
        const videoOption = ref(undefined);
        const playlistOption = ref(undefined);
        const composerOption = ref(undefined);
        const livechatOption = ref(undefined);
        const unEditable = ref(false);
        const show = ref(false);
        const emojiAllowed = ref(false);
        const { storeFile, fileForm } = useFile(RANDOM_TOKEN, "advertise_image", "panel_notifications");
        const { storeSlideShow, updateSlideshow, showSlideshow } = useNotificationList();
        const form = ref(JSON.parse(JSON.stringify(blankForm)));
        const sourceOption = ref(["artist", "music", "video", "playlist", "composer", "advertise", "live_chats"]);
        onMounted(() => {});
        const resetData = () => {
            form.value = JSON.parse(JSON.stringify(blankForm));
        };
        const { refFormObserver, getValidationState, resetForm } = formValidation(resetData);
        if (router.currentRoute.params.id) {
            slideShowId.value = router.currentRoute.params.id;
            unEditable.value = true;
        }

        // fill Form When edit page
        const showData = async () => {
            const response = await showSlideshow(slideShowId.value);
            if (response) {
                form.value = { ...blankForm, ...response };
                blankForm = { ...blankForm, ...response };
                fetchTypeList(response.notifable_type);
            } else {
                form.value = undefined;
            }
        };

        if (slideShowId.value) {
            showData();
        }

        const onSubmit = async () => {
            if (userIds.value) {
                form.value.user_ids = userIds.value.map((item) => {
                    return item.id;
                });
            }

            if (form.value.notifable_type == "advertise") {
                form.value.notifable_id = null;
            } else {
                form.value.link = null;
            }
            if (fileForm.value.file) {
                await storeFile();
            }
            show.value = true;
            delete form.value.thumbnail;
            console.log(form.value);
            storeSlideShow(form.value).then((res) => {
                show.value = false;
                context.root.$toast({
                    component: ToastificationContent,
                    props: {
                        title: "Sent!",
                        icon: "BellIcon",
                        text: "Your Message Sent Successfully :)",
                        variant: "success",
                    },
                });
            });
        };
        const users = ref();
        const filterUsers = async (search) => {
            const filters = {
                name: {
                    type: "like",
                    val: search,
                },
            };
            const params = {
                filters,
                pagination: {
                    page: 1,
                },
            };
            try {
                loadUsers(params);
            } catch (err) {
                console.log(err, "there was an error loading models");
            } finally {
            }
        };
        const loadUsers = async (param = {}) => {
            const resource = await userRepository.index(param);
            users.value = resource.data;
        };
        loadUsers();
        const fetchTypeList = (type) => {
            if (type == "artist") {
                fetchArtists();
            } else if (type == "music") {
                fetchMusics();
            } else if (type == "video") {
                fetchVideos();
            } else if (type == "playlist") {
                fetchPlaylist();
            } else if (type == "composer") {
                fetchComposer();
            } else if (type == "live_chats") {
                fetchLiveChat();
            }
        };

        const filterArtist = (search) => {
            const filters = {
                name: {
                    type: "like",
                    val: search,
                },
            };
            const params = {
                filters,
                pagination: {
                    page: 1,
                },
            };
            isLoading.value = true;
            try {
                fetchArtists(params);
            } catch (err) {
                console.log(err, "there was an error loading models");
            } finally {
                isLoading.value = false;
            }
        };
        const filterMusic = (search) => {
            const filters = {
                name: {
                    type: "like",
                    val: search,
                },
            };
            const params = {
                filters,
                pagination: {
                    page: 1,
                },
            };
            isLoading.value = true;
            try {
                fetchMusics(params);
            } catch (err) {
                console.log(err, "there was an error loading models");
            } finally {
                isLoading.value = false;
            }
        };
        const filterVideo = (search) => {
            const filters = {
                name: {
                    type: "like",
                    val: search,
                },
            };
            const params = {
                filters,
                pagination: {
                    page: 1,
                },
            };
            isLoading.value = true;
            try {
                fetchVideos(params);
            } catch (err) {
                console.log(err, "there was an error loading models");
            } finally {
                isLoading.value = false;
            }
        };
        const filterComposer = (search) => {
            const filters = {
                name: {
                    type: "like",
                    val: search,
                },
            };
            const params = {
                filters,
                pagination: {
                    page: 1,
                },
            };
            isLoading.value = true;
            try {
                fetchComposer(params);
            } catch (err) {
                console.log(err, "there was an error loading models");
            } finally {
                isLoading.value = false;
            }
        };
        const filterLiveChat = (search) => {
            const filters = {
                name: {
                    type: "like",
                    val: search,
                },
            };
            const params = {
                filters,
                pagination: {
                    page: 1,
                },
            };
            isLoading.value = true;
            try {
                fetchLiveChat(params);
            } catch (err) {
                console.log(err, "there was an error loading models");
            } finally {
                isLoading.value = false;
            }
        };
        const fetchArtists = async (param = {}) => {
            const resource = await artistRepository.index(param);
            artistOption.value = resource.data;
        };
        const fetchMusics = async (param = {}) => {
            const resource = await musicRepository.index(param);
            musicOption.value = resource.data;
        };
        const fetchVideos = async (param = {}) => {
            const resource = await videoRepository.index(param);
            videoOption.value = resource.data;
        };
        const fetchPlaylist = async (param = {}) => {
            const resource = await playlistRepository.index(param);
            playlistOption.value = resource.data;
        };
        const fetchComposer = async (param = {}) => {
            const resource = await composerRepository.index(param);
            composerOption.value = resource.data;
        };
        const fetchLiveChat = async (param = {}) => {
            const resource = await livechatRepository.index(param);
            livechatOption.value = resource.data;
            console.log(livechatOption.value);
        };

        const selectEmoji = (emoji) => {
            const input = document.querySelector("textarea");
            const start = input.selectionStart;
            const end = input.selectionEnd;
            const text = form.value.text.substring(0, start) + emoji.data + form.value.text.substring(end);
            form.value.text = text;
        };
        const emojiSelection = ref(false);
        const emojiBtn = () => {
            emojiAllowed.value = !emojiAllowed.value;
        };

        //
        const textBox = ref(null);
        const text = ref("");
        const prevSelectionStart = ref(null);

        function insertEmoji(emoji) {
            const startPos = textBox.value.selectionStart;
            const endPos = textBox.value.selectionEnd;
            const newText = form.value.text.slice(0, startPos) + emoji.data + form.value.text.slice(endPos);
            form.value.text = newText;
            focusEmoji(startPos);
        }
        function focusEmoji(startPos) {
            textBox.value.focus();
            textBox.value.selectionStart = startPos;
        }
        //
        const titleTextBox = ref(null);
        const titleEmojiAllowed = ref(false);

        const titleEmojiBtn = () => {
            titleEmojiAllowed.value = !titleEmojiAllowed.value;
        };
        function insertTitleEmoji(emoji) {
            const startPos = titleTextBox.value.selectionStart;
            const endPos = titleTextBox.value.selectionEnd;
            const newText = form.value.title.slice(0, startPos) + emoji.data + form.value.title.slice(endPos);
            form.value.title = newText;
            focusTitleEmoji(startPos);
        }
        function focusTitleEmoji(startPos) {
            titleTextBox.value.focus();
            titleTextBox.value.selectionStart = startPos;
        }
        return {
            //
            insertTitleEmoji,
            focusTitleEmoji,
            titleEmojiBtn,
            titleTextBox,
            textBox,
            text,
            insertEmoji,
            form,
            blankForm,
            fileForm,
            onSubmit,
            selectEmoji,
            refFormObserver,
            getValidationState,
            resetForm,
            required,
            slideShowId,
            sourceOption,
            isLoading,
            fetchTypeList,
            artistOption,
            musicOption,
            videoOption,
            playlistOption,
            composerOption,
            livechatOption,
            filterArtist,
            filterMusic,
            filterLiveChat,
            filterVideo,
            unEditable,
            users,
            userIds,
            filterUsers,
            emojiAllowed,
            titleEmojiAllowed,
            textArea,
            emojiBtn,
            emojiSelection,
            show,
        };
    },
    methods: {
        addHandler: function () {
            window.addEventListener("click", this.eventListenerExample);
        },
        removeHandler: function () {
            window.removeEventListener("click", this.eventListenerExample);
        },
        postMessage: function () {
            window.postMessage("This is a message ");
        },
    },
    mounted: function () {
        this.addHandler();
        this.$once("hook:destroyed", () => {
            this.removeHandler();
        });
    },
    destroyed: function () {
        this.removeHandler();
    },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
.icon {
    margin: 1px;
}
.textBoxArea {
    min-width: 1000px;
    min-height: 150px;
    border: 1px solid rgba(204, 204, 204, 0.165);
    border-radius: 10px;
    background-color: rgba(240, 248, 255, 0);
    color: aliceblue;
}
</style>
