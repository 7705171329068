import axios from "axios";
import url from "../url";
import { getArray, setQuery, getJson, setData, setComingSoonData, getComingSoonArray, getComingSoonJson } from "../resources/notificationResource";

export default class NotificationRepository {
    constructor(config = {}) {
        axios.defaults.errorHandle = config.errorHandle;
    }
    async addSlideShow(data) {
        // const json = setData(data);
        const response = await axios.post(url("sendNotification"), data);
        if (response.status === 201) {
            return response.data;
        }
    }
    async index(data) {
        const params = setQuery(data)
        const response = await axios.get(url('notifReport'), { params })
        if (response.status === 200) {
            return getArray(response.data)
        }
    }
}
