import { ref, watch } from "@vue/composition-api";

// Notification
import notificationRepository from "@/abstraction/repository/notificationRepository";

const repository = new notificationRepository();
export default function useNotification() {
    const refplaylistListTable = ref(null);
    const notifLists = ref([]);
    const perPage = ref(10);
    const perPageOptions = [10, 25, 50, 100];
    const sortBy = ref("id");
    const currentPage = ref(1);
    const searchQuery = ref("");
    const isSortDirDesc = ref(true);
    const roleFilter = ref(null);
    const planFilter = ref(null);
    const statusFilter = ref(null);
    const pagination = ref({});

    const refetchData = () => {
        refplaylistListTable.value.refresh();
    };

    watch([currentPage, perPage, searchQuery, roleFilter, planFilter, statusFilter], () => {
        refetchData();
    });

    // Table Handlers
    const tableColumns = [
        { key: "type", sortable: true },
        { key: "title", sortable: true },
        { key: "text", sortable: true },
        { key: "created_by", sortable: true },
        { key: "status", sortable: true },
    ];

    const storeSlideShow = async (data) => {
        try {
            return await repository.addSlideShow(data);
        } catch (e) {
            return false;
        }
    };

    const storeActiveSlideShows = async (data) => {
        try {
            return await repository.addActiveSlideShow(data);
        } catch (e) {
            return false;
        }
    };

    const showSlideshow = async (id) => {
        try {
            return await repository.show(id);
        } catch (e) {
            return false;
        }
    };

    const updateSlideshow = async (id, data) => {
        try {
            return await repository.update(id, data);
        } catch (e) {
            return false;
        }
    };

    const fetchNotifReport = async (paginate = { page: 1, itemsPerPage: perPage.value }, filters = {}) => {
        const notifList = await repository.index({ filters, pagination: paginate });
        notifLists.value = notifList.data;
        pagination.value = notifList.pagination;
        return notifList;
    };

    const changePagePlaylist = async (page) => {
        const response = await fetchNotifReport({ pagination: page });
        return response;
    };

    // *===============================================---*
    // *--------- UI ---------------------------------------*
    // *===============================================---*

    return {
        tableColumns,
        notifLists,
        pagination,
        perPageOptions,
        perPage,
        sortBy,
        currentPage,
        storeSlideShow,
        showSlideshow,
        updateSlideshow,
        fetchNotifReport,
        storeActiveSlideShows,
    };
}
